<template>
  <div class="wishes">
    <div class="oItem-t">
      <div class="title">发布心愿</div>
      <div class="type" @click="setShowPicker">
        <div class="type-title">
          <span>心愿类型</span>
        </div>
        <div class="type-field">
          <span class="field-placeholder" v-show="!showMap[form.wishType]">
            请选择
          </span>
          <span class="field-content" v-show="showMap[form.wishType]">{{
            showMap[form.wishType]
          }}</span>
        </div>
        <div class="type-icon">
          <img src="@/assets/img/tip.png" alt="" />
        </div>
      </div>
    </div>
    <div class="oItem-c">
      <v-input
        type="textarea"
        v-model="form.wishContent"
        :maxlength="300"
        :showWordLimit="true"
        placeholder="亲爱的小主，请说出你们的心愿，我们会逐条反馈"
      />
    </div>
    <div class="oItem-b">
      <p>上传图片</p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.wishPic1"
        :activityPicture.sync="form.wishPic"
        ref="load"
      ></v-upload>
      <p class="upload_text">建议单个图片不超过5M。</p>
    </div>
    <div class="submit" @click="submit">
      <div class="btn"><span>确认提交</span></div>
    </div>
    <v-picker
      :columns="statusList"
      :valueShow="showPicker"
      :defaultIndex="form.wishes"
      :valueKey="'value'"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
  </div>
</template>

<script>
import { postWishUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "wishes",
  data() {
    return {
      timer: null,
      statusList: [
        { key: "1", value: "愿望" },
        { key: "2", value: "祝福" },
        { key: "3", value: "其他" },
      ],
      showMap: {
        1: "愿望",
        2: "祝福",
        3: "其他",
      },
      form: {
        wishPic: "",
        wishType: "",
        wishContent: "",
      },
      showPicker: false,
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {
    // this.getActivityList();
  },
  methods: {
    async submit() {
      if (
        await toRegister(this.$route.path, this.$route.query, "未来心愿发布")
      ) {
        if (!this.form.wishContent) {
          this.$toast("心愿不能为空");
          return;
        }
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        this.postData();
      }
    },
    setShowPicker() {
      this.showPicker = true;
    },
    clickPickOverlay() {
      this.showPicker = false;
    },
    cancelPick() {
      this.showPicker = false;
    },
    confirmPick(val) {
      this.form.wishType = val.key;
      this.showPicker = false;
    },
    postData() {
      let params = {
        roomId: this.roomId,
        userId: this.userId,
        wishContent: this.form.wishContent,
        wishPic: this.form.wishPic,
        wishType: this.form.wishType,
      };
      this.$axios.post(`${postWishUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "myWishes",
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wishes {
  min-height: 100vh;
  background: #f3f4f9;
  .oItem-t,
  .oItem-c,
  .oItem-b {
    background: #fff;
  }
  .oItem-t {
    padding: 32px 0px 16px 32px;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 50px;
    }
    .type {
      font-size: 32px;
      font-weight: 400;
      color: #323334;
      // line-height: 44px;
      display: flex;
      padding: 32px 42px 32px 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .type-title,
      .type-field {
        line-height: 44px;
        span {
          vertical-align: middle;
        }
      }
      .type-field {
        flex: 1;
        text-align: right;
        .field-placeholder {
          color: #cbcccd;
        }
      }
      .type-icon {
        display: flex;
        align-items: center;
        img {
          height: 34px;
          width: 34px;
          vertical-align: middle;
        }
      }
    }
  }
  .oItem-c {
    padding: 32px;
    ::v-deep .van-field {
      background: #f7f8fa;
    }
  }
  .oItem-b {
    padding: 32px;
    p {
      font-size: 32px;
      font-weight: bold;
      color: #666666;
      line-height: 44px;
    }
    .oItem-b_upload {
      width: 160px;
      height: 160px;
      background: #f7f8fa;
      margin: 32px 0 16px;
      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }
      ::v-deep .van-uploader__preview-image {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
    .upload_text {
      font-size: 24px;
      font-weight: 400;
      color: #96979b;
      line-height: 32px;
    }
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    .btn {
      width: 100%;
      height: 96px;
      background: #178af7;
      text-align: center;
      font-size: 32px;
      line-height: 96px;
      color: #fff;
    }
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
